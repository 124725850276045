import * as types from "../types";

export const getAllGeofenceRequest = ({ requestTractorId }) => {
  return {
    type: types.GET_ALL_GEOFENCE_REQUEST,
    payload: { requestTractorId },
  };
};

export const getAllGeofenceSuccess = ({ resAllGeofence }) => {
  return {
    type: types.GET_ALL_GEOFENCE_SUCCESS,
    payload: {
      resAllGeofence,
    }
  };
};

export const getAllGeofenceFailure = (error) => {
  return {
    type: types.GET_ALL_GEOFENCE_FAILURE,
    payload: {},
    error: error,
  };
};