import * as types from "../types";

export const tractorCompanyRequest = ({ requestTractorId }) => {
  return {
    type: types.TRACTOR_COMPANY_REQUEST,
    payload: { requestTractorId },
  };
};

export const tractorCompanySuccess = ({ resTractorCompany }) => {
  return {
    type: types.TRACTOR_COMPANY_SUCCESS,
    payload: {
      resTractorCompany,
    }
  };
};

export const tractorCompanyFailure = (error) => {
  return {
    type: types.TRACTOR_COMPANY_FAILURE,
    payload: {},
    error: error,
  };
};