import * as types from "../types";

const initialState = {
  loading: false,
  requestAddNote: null,
  resAddNotes: null,
  error: null,
};

export default function addNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        requestAddNote: action.payload.requestAddNote,
      };

    case types.ADD_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        resAddNotes: action.payload.resAddNotes,
      };

    case types.ADD_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        requestAddNote: null,
        resAddNotes: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
