import * as types from "../types";

export const selectDate = ({ date }) => {
  return {
    type: types.SELECT_DATE,
    payload: {
      date,
    },
  };
};
