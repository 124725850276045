import { makeStyles } from "@material-ui/core/styles";

const tractorDescStyles = makeStyles((theme) => ({
  tractorDesc: {
    height: "calc(100vh - 60px)",
    background: theme.palette.secondary.main,
    padding: "1em",
    overflowY: "scroll",
  },
  imageBox: {
    height: "90px",
    background: "#F1F1F1",
    borderRadius: "4px",
    display: "grid",
    placeContent: "center",
    flex:"1",
    "& > img": {
      width: "95%",
      objectFit: "cover",
      objectPosition: "center",
      height: "100%",
    },
  },
  model: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 4,
    "& > p":{
      fontSize:"16px",
      fontWeight:"600",
      lineHeight:"22px",
      textTransform:"capitalize",
      color:"#000000",
    },
    "& > span":{
      fontSize:"14px",
      fontWeight:"400",
      lineHeight:"16px",
      textTransform:"capitalize",
      color:"#70367C",
      cursor: "pointer",
    }
  },
  tractorDetail: {
    display: "flex",
    flexDirection: "column",
    "& > div > h2": {
      fontSize: "1.4em",
      textTransform: "capitalize",
      display:"flex",
      alignItems:"center",
      gap:"5px"
    },
  },
  tractorDescHeader:{
    display:"flex",
    alignItems:"flex-start",
    flexDirection:"column",
    justifyContent:"space-between",
    flex:"2",
    height:"90px",
    fontSize:"14px",
    fontWeight:"400",
    textTransform:"capitalize",
    color:"#333333"
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding:"10px"
  },
  statusDesc: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  fuelDesc: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  dateDesc:{
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  engineStatus: {
    marginLeft: "auto",
    justifySelf: "flex-end",
  },
  statusDescData: {
    "& > b":{
      fontSize: "12px",
      fontWeight:"600",
      lineHeight:"19px",
    },
    "& > p": {
      margin: 0,
      padding: 0,
      fontSize: "11px",
      fontWeight:"400",
      lineHeight:"16px",
      color: "#666666",
    },
    "& > h4": {
      margin: 0,
      padding: 0,
      fontSize: "1em",
      fontWeight: "bold",
      display: "block",
    },
  },
  engineOff: {
    position: "relative",
    width: "90px",
    height: "90px",
    borderRadius: "8px",
    background: "#F1F1F1",
    display: "grid",
    placeContent: "center",
    margin:"5px 5px 5px 5px",
    border: "2px solid #FF0C22",
    "& > img": {
      width: "80%",
    },
  },
  engineOn: {
    position: "relative",
    width: "90px",
    height: "90px",
    borderRadius: "8px",
    background: "#F1F1F1",
    display: "grid",
    placeContent: "center",
    margin:"5px 5px 5px 5px",
    border: "2px solid #00c441",
    "& > img": {
      width: "80%",
    },
  },
  engineOut: {
    position: "relative",
    width: "90px",
    height: "90px",
    borderRadius: "8px",
    background: "#F1F1F1",
    display: "grid",
    placeContent: "center",
    margin:"5px 5px 5px 5px",
    border: "2px solid #808080",
    "& > img": {
      width: "80%",
    },
  },
  datePicker: {
    margin: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "1em",
    padding: "1em 0",
  },
  split: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  summaryIcon: {
    width: "36px",
    height: "36px",
  },
  summaryBlock: {
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1em",
  },
  showTrips: {
    width: "100%",
    margin: "1em 0",
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: "1em",
    },
  },
  error: {
    fontSize: "0.7em",
    color: "red",
  },
  dayWithDotContainer: {
    position: "relative",
  },
  dayWithDot: {
    position: "absolute",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    bottom: "10%",
    left: "50%",
    transform: "translateX(-50%)",
    background: theme.palette.primary.main,
  },
  filterOptions: {
    position: "absolute",
    width: "400px",
    height: "300px",
    top: "20%",
    left: "50%",
    transform: "translate(-50%,0%)",
    background: "#FFFFFF",
    outline: "none",
    border: "none",
    borderRadius: "4px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  filterOptionsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > p": {
      cursor: "pointer",
    },
  },
  inputType: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 2px",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box"
  },
  imgBorderOut:{
    position: "absolute",
    right:"0",
    height: "27px",
    padding: "3px",
    background: "#fff",
    borderRadius: "0px 8px 0px 8px",
    color:"#fff",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  imgBorderOn:{
    position: "absolute",
    right:"0",
    height: "20px",
    padding: "3px",
    background: "#00c441",
    borderRadius: "0px 0px 0px 8px",
    color:"#fff",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  imgBorderOff:{
    position: "absolute",
    right:"0",
    height: "20px",
    padding: "3px",
    background: "#FF0C22",
    borderRadius: "0px 0px 0px 8px",
    color:"#fff",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  replayButton:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "1em 0",
    color: "#70367C",
    cursor: "pointer",
    zIndex: 4,
    gap: "4px",
    textTransform:"uppercase"
  },
  edit:{
    border:"none",
    textTransform:"uppercase",
    background:"#FD7E50",
    color:"#fff",
    width:"70px",
    height:"20px",
    borderRadius:"4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap:"5px",
    cursor:"pointer",
  },
  tractorWorkHeaader:{
    fontSize:"16px",
    fontWeight:"600",
    lineHeight:"22px",
    marginBottom:"5px"
  },
   calendarHeader:{
    display:"flex",
    alignItems:"center",
    gap:"5px",
    fontSize:"12px",
    lineHeight:"16px",
    fontWeight:"600",
    marginBottom:"5px",
    cursor:"pointer"
  },
  geofenceHeader:{
    fontSize:"18px",
    fontWeight:"600",
    marginBottom:"10px"
  },
  geofenceWrapper:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    gap:"10px"
  },
  geofenceTab:{
    display:"flex",
    flexDirection:"column",
    flex:"1",
    background:"#f9f9f9",
    padding:"10px",
    borderRadius:"4px",
    fontSize:"14px",
    cursor:"pointer"
  }
}));

export default tractorDescStyles;
