import * as types from "../types";

const initialState = {
  loading: false,
  otpSent: false,
  phone: null,
  otpSuccess:null,
  error: {},
};

const otpRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OTP_REQUEST:
      return {
        ...state,
        phone: action.payload,
        otpSent: false,
        loading: true,
        error: {},
      };
    case types.OTP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        phone: action.payload.phone,
        otpSent: action.payload.otpSuccess.status,
        otpSuccess: action.payload.otpSuccess,
        error: {},
      };
    case types.OTP_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        phone:null,
        otpSent: false,
        otpSuccess: null,
        error: action.error,
      };
    case types.OTP_RESET:
      return {
        ...state,
        loading: false,
        otpSent: false,
        otpSuccess:null,
        error: {},
        phone: null,
      };
    default:
      return state;
  }
};

export default otpRequestReducer;
