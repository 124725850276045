import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  fetchDateRangeDataSuccess,
  fetchDateRangeDataFailure,
} from "../actions/dateRangeDataAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncDateRangeDataSaga(action) {
  try {
    yield put(clearErrors());
    console.log("dateRangeSaga", action.payload.requestDateRange);
    let response = yield all(
      new Array(action.payload.requestDateRange.dateLength)
        .fill(0)
        .map((_, index) => {
          return call(_api.fetchDayDetail, {
              deviceId: action.payload.requestDateRange.device_Id,
              date: action.payload.requestDateRange.datesArray[index],
          });
        })
    );
    // const res2 = yield call(_api.fetchDayDetail, {
    //       deviceId: action.payload.requestDateRange.device_Id,
    //       date: action.payload.requestDateRange.date,
    // });
    yield put(fetchDateRangeDataSuccess({ dateRangeData: response }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchDateRangeDataFailure({ error: e }));
  }
}

export default function* dateRangeDataSaga() {
  yield takeEvery(types.DATE_RANGE_SUMMARY_REQUEST, asyncDateRangeDataSaga);
}