import * as types from "../types";

const initialState = {
  loading: false,
  date: null,
  realTime: null,
  tractor: null,
  area: null,
  totalTime: null,
  kms: null,
  status: null,
  fuelLevel: null,
  lastActive: null,
  dailyStatus: null,
  commutes: null,
  patches: null,
  replay: null,
  patchesLatsCentroid:null,
  patchesLongsCentroid:null,
  patchesArea:null,
  patchesStartTime:null,
  patchesEndTime:null,
  error: {},
};

const daySummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DAY_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        tractor: action.payload.tractor,
        realTime: action.payload.realTime,
        date: action.payload.date,
        dailyStatus: null,
        error: {},
      };
    case types.DAY_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        area: action.payload.area,
        totalTime: action.payload.totalTime,
        kms: action.payload.kms,
        status: action.payload.status,
        fuelLevel: action.payload.fuelLevel,
        lastActive: action.payload.lastActive,
        dailyStatus: action.payload.dailyStatus,
        commutes: action.payload.commutes,
        patches: action.payload.patches,
        replay: action.payload.replay,
        patchesLatsCentroid:action.payload.patchesLatsCentroid,
        patchesLongsCentroid:action.payload.patchesLongsCentroid,
        patchesArea: action.payload.patchesArea,
        patchesStartTime: action.payload.patchesStartTime,
        patchesEndTime: action.payload.patchesEndTime,
        error: {},
      };
    case types.DAY_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default daySummaryReducer;
