export const tripTimeFromDaySummary = (totalTime) => {
  let dataToDisplay = [];
  let secs = totalTime;
  let mins = Math.trunc(secs / 60);
  let hours = Math.trunc(mins / 60);

  if (hours < 10) {
    dataToDisplay.push("0");
    dataToDisplay.push(hours);
  } else {
    dataToDisplay.push(hours);
  }

  dataToDisplay.push(":");
  mins %= 60;
  if (mins < 10) {
    dataToDisplay.push("0");
    dataToDisplay.push(mins);
  } else {
    dataToDisplay.push(mins);
  }
  return dataToDisplay.join("");
};

export const getTimeDiff = (deviceTime) => {
  let deviceLastSeen = new Date(deviceTime);
  let currentTime = new Date();
  let diffInMs = currentTime.getTime() - deviceLastSeen.getTime();

  const diffTime = [];

  const msInDays = 1000 * 60 * 60 * 24;
  const msInHour = 1000 * 60 * 60;
  const msInMinute = 1000 * 60;

  let diffInMins = Math.trunc(diffInMs / msInMinute);
  let diffInHrs = Math.trunc(diffInMs / msInHour);
  let diffInDays = Math.trunc(diffInMs / msInDays);
  let diffInMonth = Math.trunc(diffInDays / 30);
  let diffInYear = Math.trunc(diffInMonth / 12);

  if (diffInMins < 60) {
    if (diffInMins > 1) {
      diffTime.push(diffInMins, "Mins", " ", "Ago");
    } else if (diffInMins === 0) {
      diffTime.push("Just Now");
    } else {
      diffTime.push(diffInMins, "Min", " ", "Ago");
    }
  } else if (diffInHrs < 24) {
    if (diffInHrs > 1) {
      diffTime.push(diffInHrs, "Hours", " ", "Ago");
    } else {
      diffTime.push(diffInHrs, "Hour", " ", "Ago");
    }
  } else if (diffInDays < 30) {
    if (diffInDays > 1) {
      diffTime.push(diffInDays, "Days", " ", "Ago");
    } else {
      diffTime.push(diffInDays, "Day", " ", "Ago");
    }
  } else if (diffInMonth < 12) {
    if (diffInMonth > 1) {
      diffTime.push(diffInMonth, "Months", " ", "Ago");
    } else {
      diffTime.push(diffInMonth, "Month", " ", "Ago");
    }
  } else {
    if (diffInYear > 1) {
      diffTime.push(diffInYear, "Years", " ", "Ago");
    } else {
      diffTime.push(diffInYear, "Year ", " ", "Ago");
    }
  }

  return diffTime.join(" ");
};

export const getTimeStatusDiff = (deviceTime) => {
  let deviceLastSeen = new Date(deviceTime);
  let currentTime = new Date();
  let diffInMs = currentTime.getTime() - deviceLastSeen.getTime();

  const msInMinute = 1000 * 60;

  let diffInMins = Math.trunc(diffInMs / msInMinute);
  return diffInMins;

};

export const formattedDate = (date) => {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  return `${year}-${month}-${today}`;
};

export const formattedEditDate = (date) => {
  let editDate=new Date(date);
  let month = editDate.getMonth() + 1;
  let year = editDate.getFullYear();
  let today = editDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  return `${year}-${month}-${today}`;
};

export const formattedSlashDate = (date) => {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  return `${today}/${month}/${year}`;
};

export const parsedFuelAndStatus = (realtime, tractor) => {
  const parsed = [];
  if (realtime.status >= 0) {
    let int = parseInt(realtime.status);
    let some = Number(int).toString(2);
    //pad 0 so that length is 32 and replace " " with 0
    const charArray = [...some];

    let status = updateStatusReg(charArray);
    if (status === "NO") {
      if (realtime.speed === 0) {
        status = "IDLE";
      } else if (realtime.time < 300000) {
        status = "ON";
      } else {
        status = "--";
      }
    }

    parsed.push(status);
  }

  const {
    fuel_range_params: { start_fuel, end_fuel, fuel_accuracy },
  } = tractor;

  const fuel = computeFuelToDisplay(
    realtime.fuel_level_dashboard,
    tractor.app_mask_flags.fuel,
    start_fuel,
    end_fuel,
    fuel_accuracy
  );
  parsed.push(fuel);

  return parsed;
};

function computeFuelToDisplay(
  fuelInLitres,
  fuelMask,
  startFuel,
  endFuel,
  fuelAccuracy
) {
  if (fuelMask === 1) {
    //show Fuel
    if (fuelInLitres < startFuel) return "< " + startFuel;
    else if (fuelInLitres >= endFuel) return "> " + endFuel;
    else if (fuelAccuracy > 0) {
      let stepsToCover = fuelInLitres - startFuel;
      let remainderSteps = stepsToCover % fuelAccuracy;
      let rangeStart = fuelInLitres - remainderSteps;
      let rangeEnd = rangeStart + fuelAccuracy;
      return rangeStart + " - " + rangeEnd;
    } else {
      return fuelInLitres.toString();
    }
  } else {
    return "-";
  }
}

function updateStatusReg(charArray) {
  //0 - trip on/off
  //1 - ign on/off
  //2 - tractor status idle/running
  //3 - pto on/off
  //4 - ploughing on/off
  let status = "";
  if (charArray[charArray.length - 1] === "1") {
    if (charArray[charArray.length - 3] === "1") {
      status = "ON";
    } else {
      status = "IDLE";
    }
  } else {
    status = "OFF";
  }
  return status;
}

export const timeDisplay = (time) => {
  let dateObject = new Date(time);
  let humanDateFormat = dateObject.toLocaleTimeString("en-US");

  return humanDateFormat;
};

export const dateDisplay = (time) => {
  let dateObject = new Date(time);
  let humanDate = dateObject.toLocaleDateString("en-IN");

  return humanDate;
};