import * as types from "../types";

const initialState = {
  loading: false,
  requestTractorId: null,
  resTractorCompany: null,
  error: null,
};

export default function tractorCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case types.TRACTOR_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        requestTractorId: action.payload.requestTractorId,
      };

    case types.TRACTOR_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        resTractorCompany: action.payload.resTractorCompany,
      };

    case types.TRACTOR_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        requestTractorId: null,
        resTractorCompany: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
