import * as types from "../types";

export const otpRequest = (phone) => {
  return {
    type: types.OTP_REQUEST,
    payload: phone,
  };
};

export const otpRequestSuccess = ({otpSuccess,phone}) => {
  return {
    type: types.OTP_REQUEST_SUCCESS,
    payload:{
      otpSuccess,
      phone
    }
  };
};

export const otpRequestFailure = (error) => {
  return {
    type: types.OTP_REQUEST_FAILURE,
    payload: {},
    error: error,
  };
};

export const otpReset = () => {
  return {
    type: types.OTP_RESET,
  };
};
