import * as types from "../types";

const initialState = {
  loading: false,
  requestEditNote: null,
  resEditNote: null,
  error: null,
};

export default function editNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.EDIT_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        requestEditNote: action.payload.requestEditNote,
      };

    case types.EDIT_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        resEditNote: action.payload.resEditNote,
      };

    case types.EDIT_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        requestEditNote: null,
        resEditNote: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
