import { takeLatest, call, put } from "redux-saga/effects";
import { doneNotesFailure, doneNotesSuccess } from "../actions/doneNoteAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncDoneNotesSaga(action) {
  try {
    yield put(clearErrors());
    let resDoneNote = yield call(_api.doneNotesAPI, {
      requestDoneNoteId: action.payload.requestDoneNoteId,
    });
    yield put(doneNotesSuccess({ resDoneNote }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(doneNotesFailure({ error: e }));
  }
}
export default function* doneNotesSaga() {
  yield takeLatest(types.DONE_NOTES_REQUEST, asyncDoneNotesSaga);
}