import * as types from "../types";

export const editNotesRequest = ({ requestEditNote }) => {
  return {
    type: types.EDIT_NOTES_REQUEST,
    payload: { requestEditNote },
  };
};

export const editNotesSuccess = ({ resEditNote }) => {
  return {
    type: types.EDIT_NOTES_SUCCESS,
    payload: {
      resEditNote,
    }
  };
};

export const editNotesFailure = (error) => {
  return {
    type: types.EDIT_NOTES_FAILURE,
    payload: {},
    error: error,
  };
};