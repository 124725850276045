import * as types from "../types";

export const addNotesRequest = ({ requestAddNote }) => {
  return {
    type: types.ADD_NOTES_REQUEST,
    payload: { requestAddNote },
  };
};

export const addNotesSuccess = ({ resAddNotes }) => {
  return {
    type: types.ADD_NOTES_SUCCESS,
    payload: {
      resAddNotes,
    }
  };
};

export const addNotesFailure = (error) => {
  return {
    type: types.ADD_NOTES_FAILURE,
    payload: {},
    error: error,
  };
};