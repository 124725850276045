import * as types from "../types";

export const realtimeRequest = ({ phone }) => {
  return {
    type: types.REALTIME_REQUEST,
    payload: { phone },
  };
};

export const realtimeRequestSuccess = ({ realtimeData }) => {
  return {
    type: types.REALTIME_SUCCESS,
    payload: {
      realtimeData,
    }
  };
};

export const realtimeRequestFailure = (error) => {
  return {
    type: types.REALTIME_FAILURE,
    payload: {},
    error: error,
  };
};

export const realtimeReset = () => {
  return {
    type: types.REALTIME_RESET,
  };
};