import { takeLatest, call, put } from "redux-saga/effects";
import {
  addPatchesFailure,
  addPatchesSuccess,
} from "../actions/addPatchAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncAddPatchesSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.addUpdatePatch, {
      requestData: action.payload.requestData,
    });
    yield put(addPatchesSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(addPatchesFailure({ error: e }));
  }
}
export default function* addPatchesSaga() {
  yield takeLatest(
    types.ADD_PATCHES_REQUEST,
    asyncAddPatchesSaga
  );
}