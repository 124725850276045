import * as types from "../types";

const initialState = {
  loading: false,
  requestUserID: null,
  resNotes: null,
  error: null,
};

export default function getNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        requestUserID: action.payload.requestUserID,
      };

    case types.GET_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        resNotes: action.payload.resNotes,
      };

    case types.GET_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        requestUserID: null,
        resNotes: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
