import * as types from "../types";

const initialState = {
  loading: false,
  requestDoneNoteId: null,
  resDoneNote: null,
  error: null,
};

export default function doneNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.DONE_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        requestDoneNoteId: action.payload.requestDoneNoteId,
      };

    case types.DONE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        resDoneNote: action.payload.resDoneNote,
      };

    case types.DONE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        requestDoneNoteId: null,
        resDoneNote: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
