export const API_ROUTES = {
  LOGIN: "/users/auth/verify/",
  REALTIME_TRACTOR_DATA:"/tractor/get_fleet_data/",
  OTP_REQUEST:"/users/auth/login/",
  DAY_SUMMARY_DATA:"/data/summary/day/",
  DAY_DETAIL_DATA:"/data/details/day/",
  TRIP_DATES:"/data/count/",
  NOTIFICATIONS:"/alerts/tractor/",
  GROMAX_DEVICES:"/devices/get/gromax_devices/",
  IMMOBILIZE_DEVICE:"/devices/send_immobilize/",
  ADD_UPDATE_PATCH:"/data/modify_remove_patches/",
  GET_NOTES:"/tractor/get_notes/",
  ADD_NOTES:"/tractor/add_note/",
  DELETE_NOTE:"/tractor/delete_note/",
  EDIT_NOTE:"/tractor/edit_note/",
  COMPLETED_NOTE:"/tractor/complete_note/",
  TRACTOR_COMPANY:"/tractor/makes/",
  GET_ALL_GEOFENCE:"/fleet/tractor/get_all_geofences/",
  EDIT_GEOFENCE:"/fleet/tractor/edit_geofence/",
  TODAY_STATS:"/fleet/get_overall_status/"
};

export const API_BASE_URL = {
  PARENT_URL: process.env.REACT_APP_PARENT_URL
    ? process.env.REACT_APP_PARENT_URL
    : "https://production.carnot-traclytics.com",
};