import * as types from "../types";

const initialState = {
  loading: false,
  requestDeleteNoteId: null,
  resDeleteNote: null,
  error: null,
};

export default function deleteNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        requestDeleteNoteId: action.payload.requestDeleteNoteId,
      };

    case types.DELETE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        resDeleteNote: action.payload.resDeleteNote,
      };

    case types.DELETE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        requestDeleteNoteId: null,
        resDeleteNote: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
