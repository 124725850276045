import { takeLatest, call, put } from "redux-saga/effects";
import { tractorDetailFailure, tractorDetailSuccess } from "../actions/tractorDetailAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncTractorDetailUpdateSaga(action) {
  try {
    yield put(clearErrors());
    let tractorDataUpdated = yield call(_api.updateTractorData, {
      requestTractorDetail: action.payload.requestTractorDetail,
    });
    yield put(tractorDetailSuccess({ tractorDataUpdated }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(tractorDetailFailure({ error: e }));
  }
}
export default function* tractorDetailUpdateSaga() {
  yield takeLatest(types.TRACTOR_DETAIL_REQUEST, asyncTractorDetailUpdateSaga);
}