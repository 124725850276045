import * as types from "../types";

const initialState = {
  userActionPoints: null,
  error: null,
};

export default function userPatchReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_ACTION_PATCH_PERFORMED:

      return {
        ...state,
        userActionPoints: state.userActionPoints
          ? [...state.userActionPoints, action.payload.newPoints]
          : [action.payload.newPoints],
      };

    case types.RESET_USER_ACTION_PATCH_PERFORMED:
      return {
        ...state,
        userActionPoints: null,
        error: null,
      };

    default:
      return state;
  }
}