import * as types from "../types";

export const fetchUserPatchAction = ({ newPoints }) => ({
  type: types.USER_ACTION_PATCH_PERFORMED,
  payload: {
    newPoints,
  },
});

export const resetFetchUserPatchAction = () => ({
  type: types.RESET_USER_ACTION_PATCH_PERFORMED,
});