import axios from "axios";
import { parseDayDetails } from "../utils/MapUtils";
import { API_BASE_URL, API_ROUTES } from "../apiConfig";

const headerParams = { "app-version": 26.6 };
axios.defaults.baseURL = "https://production.carnot-traclytics.com";

export const fetchTractorListData = async ({ phoneNumber, otp }) => {
  const demoNumbers = ["9423049272", "9634200177", "9627823355", "9628404040"];

  if (demoNumbers.includes(phoneNumber) && otp === 1059) {
    otp = "6145";
  }
  try {
    const { data } = await axios.post(`${API_BASE_URL.PARENT_URL}${API_ROUTES.LOGIN}`, {
      phone: phoneNumber,
      otp: otp,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchRealtimeTractorData = async ({ phone}) => {

  try {
    const { data } = await axios.post(`${API_BASE_URL.PARENT_URL}${API_ROUTES.REALTIME_TRACTOR_DATA}`, {
      phone: phone,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const makeOTPRequestCall = async (phoneNumber) => {
  try {
    let debugMode = false;
    if (
      process.env.REACT_APP_DEBUG_MODE &&
      process.env.REACT_APP_DEBUG_MODE === "true"
    ) {
      debugMode = true;
    }
    const { data } = await axios.post(`${API_BASE_URL.PARENT_URL}${API_ROUTES.OTP_REQUEST}`, {
      phone: phoneNumber,
      debug: debugMode,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// export const fetchDaySummaryData = async (tractors) => {
//   const daySummary = [];
//   let date = new Date();
//   let month = date.getMonth() + 1;
//   let year = date.getFullYear();
//   let today = date.getDate();
//   if (month < 10) {
//     month = "0" + month;
//   }

//   if (today < 10) {
//     today = "0" + today;
//   }

//   for (let tractor of tractors) {
//     const { data } = await axios.get(
//       `/data/summary/day/${tractor.device_pk}/${year}/${month}/${today}/0/`,
//       { headers: headerParams }
//     );
//     daySummary.push(data);
//   }

//   return daySummary;
// };

export const fetchDaySummary = async ({ deviceId, date }) => {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();
  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  const { data } = await axios.get(
    `${API_BASE_URL.PARENT_URL}${API_ROUTES.DAY_SUMMARY_DATA}${deviceId}/${year}/${month}/${today}/0/`,
    { headers: headerParams }
  );

  return data;
};

export const fetchDayDetail = async ({ deviceId, date }) => {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  const {
    data: { data },
  } = await axios.get(
    `${API_BASE_URL.PARENT_URL}${API_ROUTES.DAY_DETAIL_DATA}${deviceId}/${year}/${month}/${today}/0/`,
    { headers: headerParams }
  );
  return parseDayDetails(data);
};

export const fetchTripsDates = async ({ deviceId }) => {
  let date = new Date();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let today = date.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  if (today < 10) {
    today = "0" + today;
  }

  const {
    data: { data },
  } = await axios.get(
    `${API_BASE_URL.PARENT_URL}${API_ROUTES.TRIP_DATES}${deviceId}/${year}/${month}/${today}/180/`,
    {
      headers: headerParams,
    }
  );
  return data;
};

export const fetchAddressFromPoints = async (points) => {
  const names = [];
  for (let point of points) {
    const {
      data: { display_name },
    } = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${point.realtime.latitude}&lon=${point.realtime.longitude}`
    );
    names.push(display_name);
  }

  return names;
};

export const fetchNotification = async ({ id }) => {
  // const notify;
  const { data } = await axios.get(`${API_BASE_URL.PARENT_URL}${API_ROUTES.NOTIFICATIONS}${id}/`, {
    headers: headerParams,
  });
  return data;
};

export const checkPasswordForImmobilize = ({ password }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (password.toString() === "12345678") {
        resolve({ status: true, msg: "Password Matched!!" });
      } else {
        reject({ status: false, msg: "Incorrect Password!!" });
      }
    }, 1500);
  });
};

export const getGromaxDevices = async () => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.PARENT_URL}${API_ROUTES.GROMAX_DEVICES}`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const immobalizeDevice = async ({ id }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.PARENT_URL}${API_ROUTES.IMMOBILIZE_DEVICE}${id}/`
    );
    return data;
  } catch (e) {
    throw e;
  }
};

//Update Tractor Name
export const updateTractorData = async ({ requestTractorDetail}) => {

  try {
    const { data } = await axios.post(`/tractor/${requestTractorDetail.id}/details/`, requestTractorDetail);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addUpdatePatch = async ({ requestData }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.ADD_UPDATE_PATCH}`,
      data: requestData,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotesAPI = async ({ requestUserID }) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.GET_NOTES}${requestUserID.userID}/`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addNotesAPI = async ({ requestAddNote }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.ADD_NOTES}`,
      data: requestAddNote
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteNotesAPI = async ({ requestDeleteNoteId }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.DELETE_NOTE}`,
      data: requestDeleteNoteId
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const editNotesAPI = async ({ requestEditNote }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.EDIT_NOTE}`,
      data: requestEditNote
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const doneNotesAPI = async ({ requestDoneNoteId }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.COMPLETED_NOTE}${requestDoneNoteId.noteid}/`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const satellitePatchData = async ({ requestPatch }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `https://4gv48ymxwc.execute-api.us-east-2.amazonaws.com/dev/satellite/{patch_area}/{boundary_points}`,
      data: requestPatch
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const tractorCompanyAPI = async ({ requestTractorId }) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.TRACTOR_COMPANY}${requestTractorId.deviceId}`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const tractorModelAPI = async ({ requestTractorModelId }) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `https://traclytics-staging.herokuapp.com/tractor/${requestTractorModelId.company}/models/${requestTractorModelId.deviceId}`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const  getAllGeofence = async ({ requestTractorId }) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.GET_ALL_GEOFENCE}${requestTractorId.deviceId}`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const editGeofenceAPI = async ({ requestEditGeofence }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.EDIT_GEOFENCE}${requestEditGeofence.deviceId}`,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTodayStatusAPI = async ({ requestUserID }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.PARENT_URL}${API_ROUTES.TODAY_STATS}`,
      data: requestUserID
    });
    return data;
  } catch (error) {
    throw error;
  }
};