import * as types from "../types";

export const editGeofenceRequest = ({ requestEditGeofence }) => {
  return {
    type: types.EDIT_GEOFENCE_REQUEST,
    payload: { requestEditGeofence },
  };
};

export const editGeofenceSuccess = ({ resEditGeofence }) => {
  return {
    type: types.EDIT_GEOFENCE_SUCCESS,
    payload: {
      resEditGeofence,
    }
  };
};

export const editGeofenceFailure = (error) => {
  return {
    type: types.EDIT_GEOFENCE_FAILURE,
    payload: {},
    error: error,
  };
};