// ERROR TYPES
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// OTP REQUEST TYPES
export const OTP_REQUEST = "OTP_REQUEST";
export const OTP_REQUEST_SUCCESS = "OTP_REQUEST_SUCCESS";
export const OTP_REQUEST_FAILURE = "OTP_REQUEST_FAILURE";
export const OTP_RESET = "OTP_RESET";

// LOGIN REQUEST TYPES
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_UPDATE = "LOGIN_UPDATE";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

// LOGIN REQUEST TYPES
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const LOGOUT_REQUEST_FAILURE = "LOGOUT_REQUEST_FAILURE";

// FILTER TRACTOR TYPES
export const FILTER_TRACTOR_REQUEST = "FILTER_TRACTOR_REQUEST";
export const FILTER_TRACTOR_SUCCESS = "FILTER_TRACTOR_SUCCESS";
export const FILTER_TRACTOR_FAILURE = "FILTER_TRACTOR_FAILURE";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";

// FETCH TRACTOR TYPES
export const FETCH_TRACTOR_LIST = "FETCH_TRACTOR_LIST";
export const FETCH_TRACTOR_LIST_SUCCESS = "FETCH_TRACTOR_LIST_SUCCESS";
export const FETCH_TRACTOR_LIST_FAILURE = "FETCH_TRACTOR_LIST_FAILURE";

// FETCH DAY SUMMARY
export const DAY_SUMMARY_REQUEST = "DAY_SUMMARY_REQUEST";
export const DAY_SUMMARY_SUCCESS = "DAY_SUMMARY_SUCCESS";
export const DAY_SUMMARY_FAILURE = "DAY_SUMMARY_FAILURE";

// SELECTED TRACTOR DETAILS
export const TRACTOR_REQUEST = "TRACTOR_REQUEST";
export const TRACTOR_REQUEST_SUCCESS = "TRACTOR_REQUEST_SUCCESS";
export const TRACTOR_REQUEST_FAILURE = "TRACTOR_REQUEST_FAILURE";

//FETCH NOTIFICATION
export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILURE = "FETCH_NOTIFICATION_FAILURE";

// GROMAX DEVICES
export const GET_GROMAX_REQUEST = "GET_GROMAX_REQUEST";
export const GET_GROMAX_SUCCESS = "GET_GROMAX_SUCCESS";
export const GET_GROMAX_FAILURE = "GET_GROMAX_FAILURE";

//SELECT DATE
export const SELECT_DATE = "SELECT_DATE";


export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILURE = "UPDATE_FAILURE";

//TRACTOR REALTIME
export const REALTIME_REQUEST = "REALTIME_REQUEST";
export const REALTIME_SUCCESS = "REALTIME_SUCCESS";
export const REALTIME_FAILURE = "REALTIME_FAILURE";
export const REALTIME_RESET = "REALTIME_RESET";

// UPDATE TRACTOR DETAIL
export const TRACTOR_DETAIL_REQUEST = "TRACTOR_DETAIL_REQUEST";
export const TRACTOR_DETAIL_SUCCESS = "TRACTOR_DETAIL_SUCCESS";
export const TRACTOR_DETAIL_FAILURE = "TRACTOR_DETAIL_FAILURE";
export const TRACTOR_DETAIL_RESET = "TRACTOR_DETAIL_RESET";

// DRAWN/UPDATE PATCHES
export const ADD_PATCHES_REQUEST = "ADD_PATCHES_REQUEST";
export const ADD_PATCHES_SUCCESS = "ADD_PATCHES_SUCCESS";
export const ADD_PATCHES_FAILURE = "ADD_PATCHES_FAILURE";

//USER ACTION
export const USER_ACTION_PATCH_PERFORMED = "USER_ACTION_PATCH_PERFORMED";
export const RESET_USER_ACTION_PATCH_PERFORMED = "RESET_USER_ACTION_PATCH_PERFORMED";

//USER ACTION
export const USER_ACTION_NOTES_PERFORMED = "USER_ACTION_NOTES_PERFORMED";
export const RESET_USER_ACTION_NOTES_PERFORMED = "RESET_USER_ACTION_NOTES_PERFORMED";

// LOCAL NAME UPDATE
export const LOCAL_TRACTOR_NAME = "LOCAL_TRACTOR_NAME";
export const RESET_LOCAL_TRACTOR_NAME = "RESET_LOCAL_TRACTOR_NAME";

//GET NOTES
export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAILURE = "GET_NOTES_FAILURE";

//ADD NOTES
export const ADD_NOTES_REQUEST = "ADD_NOTES_REQUEST";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_FAILURE = "ADD_NOTES_FAILURE";

//GET NOTES
export const EDIT_NOTES_REQUEST = "EDIT_NOTES_REQUEST";
export const EDIT_NOTES_SUCCESS = "EDIT_NOTES_SUCCESS";
export const EDIT_NOTES_FAILURE = "EDIT_NOTES_FAILURE";

//DELETE NOTES
export const DELETE_NOTES_REQUEST = "DELETE_NOTES_REQUEST";
export const DELETE_NOTES_SUCCESS = "DELETE_NOTES_SUCCESS";
export const DELETE_NOTES_FAILURE = "DELETE_NOTES_FAILURE";

//DONE NOTES
export const DONE_NOTES_REQUEST = "DONE_NOTES_REQUEST";
export const DONE_NOTES_SUCCESS = "DONE_NOTES_SUCCESS";
export const DONE_NOTES_FAILURE = "DONE_NOTES_FAILURE";

// SATELLITE PATCH
export const FETCH_SATELLITE_PATCH_REQUEST = "FETCH_SATELLITE_PATCH_REQUEST";
export const FETCH_SATELLITE_PATCH_SUCCESS = "FETCH_SATELLITE_PATCH_SUCCESS";
export const FETCH_SATELLITE_PATCH_FAILURE = "FETCH_SATELLITE_PATCH_FAILURE";

//DATE RANGE DATA
export const DATE_RANGE_SUMMARY_REQUEST = "DATE_RANGE_SUMMARY_REQUEST";
export const DATE_RANGE_SUMMARY_SUCCESS = "DATE_RANGE_SUMMARY_SUCCESS";
export const DATE_RANGE_SUMMARY_FAILURE = "DATE_RANGE_SUMMARY_FAILURE";
export const DATE_RANGE_SUMMARY_RESET = "DATE_RANGE_SUMMARY_RESET";

//TRACTOR COMPANY
export const TRACTOR_COMPANY_REQUEST = "TRACTOR_COMPANY_REQUEST";
export const TRACTOR_COMPANY_SUCCESS = "TRACTOR_COMPANY_SUCCESS";
export const TRACTOR_COMPANY_FAILURE = "TRACTOR_COMPANY_FAILURE";

//TRACTOR MODEL
export const TRACTOR_MODEL_REQUEST = "TRACTOR_MODEL_REQUEST";
export const TRACTOR_MODEL_SUCCESS = "TRACTOR_MODEL_SUCCESS";
export const TRACTOR_MODEL_FAILURE = "TRACTOR_MODEL_FAILURE";

//GET ALL GEOFENCE
export const GET_ALL_GEOFENCE_REQUEST = "GET_ALL_GEOFENCE_REQUEST";
export const GET_ALL_GEOFENCE_SUCCESS = "GET_ALL_GEOFENCE_SUCCESS";
export const GET_ALL_GEOFENCE_FAILURE = "GET_ALL_GEOFENCE_FAILURE";

//EDIT GEOFENCE
export const EDIT_GEOFENCE_REQUEST = "EDIT_GEOFENCE_REQUEST";
export const EDIT_GEOFENCE_SUCCESS = "EDIT_GEOFENCE_SUCCESS";
export const EDIT_GEOFENCE_FAILURE = "EDIT_GEOFENCE_FAILURE";

//TODAY STATUS
export const GET_TODAY_STATUS_REQUEST = "GET_TODAY_STATUS_REQUEST";
export const GET_TODAY_STATUS_SUCCESS = "GET_TODAY_STATUS_SUCCESS";
export const GET_TODAY_STATUS_FAILURE = "GET_TODAY_STATUS_FAILURE";