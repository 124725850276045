import * as types from "../types";

export const loginRequest = ({ phone, otp }) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: { phone, otp },
  };
};

export const loginRequestSuccess = ({ data, status, message, realTimes }) => {
  return {
    type: types.LOGIN_REQUEST_SUCCESS,
    payload: {
      data,
    },
    status,
    message,
    realTimes,
  };
};

export const loginRequestFailure = (error) => {
  return {
    type: types.LOGIN_REQUEST_FAILURE,
    payload: {},
    error: error,
  };
};

export const loginUpdate = ({ tractors}) => {
  return {
    type: types.LOGIN_UPDATE,
    payload: {
      tractors,
    },
  };
};

// export const loginDescUpdate = (data) => {
//   return {
//     type: types.LOGIN_DESC_UPDATE,
//     payload: {
//       data,
//     },
//   };
// };
