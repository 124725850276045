import * as types from "../types";

const initialState = {
  loading: false,
  requestData: null,
  res: null,
  error: null,
};

export default function addPatchesReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.ADD_PATCHES_REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.payload.requestData,
      };

    case types.ADD_PATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.payload.res,
      };

    case types.ADD_PATCHES_FAILURE:
      return {
        ...state,
        loading: false,
        requestData: null,
        res: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}