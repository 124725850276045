import * as types from "../types";

export const deleteNotesRequest = ({ requestDeleteNoteId }) => {
  return {
    type: types.DELETE_NOTES_REQUEST,
    payload: { requestDeleteNoteId },
  };
};

export const deleteNotesSuccess = ({ resDeleteNote }) => {
  return {
    type: types.DELETE_NOTES_SUCCESS,
    payload: {
      resDeleteNote,
    }
  };
};

export const deleteNotesFailure = (error) => {
  return {
    type: types.DELETE_NOTES_FAILURE,
    payload: {},
    error: error,
  };
};