import * as types from "../types";

export const fetchDateRangeDataRequest = ({ requestDateRange }) => ({
  type: types.DATE_RANGE_SUMMARY_REQUEST,
  payload: {
    requestDateRange,
  },
});

export const fetchDateRangeDataSuccess = ({ dateRangeData }) => ({
  type: types.DATE_RANGE_SUMMARY_SUCCESS,
  payload: {
    dateRangeData,
  },
});

export const fetchDateRangeDataFailure = ({ error }) => ({
  type: types.DATE_RANGE_SUMMARY_FAILURE,
  payload: {
    error: error.message,
  },
});

export const dateRangeDataReset = () => {
  return {
    type: types.DATE_RANGE_SUMMARY_RESET,
  };
};