import * as types from "../types";

export const getNotesRequest = ({ requestUserID }) => {
  return {
    type: types.GET_NOTES_REQUEST,
    payload: { requestUserID },
  };
};

export const getNotesSuccess = ({ resNotes }) => {
  return {
    type: types.GET_NOTES_SUCCESS,
    payload: {
      resNotes,
    }
  };
};

export const getNotesFailure = (error) => {
  return {
    type: types.GET_NOTES_FAILURE,
    payload: {},
    error: error,
  };
};