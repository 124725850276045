import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  fetchSatellitePatchFailure,
  fetchSatellitePatchSuccess,
} from "../actions/satellitePatchAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncSatellitePatchSaga(action) {
  try {
    yield put(clearErrors());
    let response = yield all(
      new Array(action.payload.requestPatch.patchesLength)
        .fill(0)
        .map((_, index) => {
          return call(_api.satellitePatchData, {
            requestPatch: {
              patch_area: action.payload.requestPatch.patchesArea[index],
              boundary_points: action.payload.requestPatch.patches[index],
            },
          });
        })
    );
    yield put(fetchSatellitePatchSuccess({ satellitePatch: response }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchSatellitePatchFailure({ error: e }));
  }
}

export default function* satellitePatchSaga() {
  yield takeEvery(types.FETCH_SATELLITE_PATCH_REQUEST, asyncSatellitePatchSaga);
}