import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { getGromaxSuccess, getGromaxFailure } from "../actions/getGromaxAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";

function* asyncGetGromaxRequestSaga(action) {
  try {
    yield put(clearErrors());
    const res = yield call(_api.getGromaxDevices);
    yield put(getGromaxSuccess({ data: res.data }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(getGromaxFailure(e));
  }
}

export default function* getGromaxRequestSaga() {
  yield takeLatest(types.GET_GROMAX_REQUEST, asyncGetGromaxRequestSaga);
}
