import * as types from "../types";

const initialState = {
  loading: false,
  data: null,
  error: {},
};

const getGromaxReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GROMAX_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case types.GET_GROMAX_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: {},
      };
    case types.GET_GROMAX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default getGromaxReducer;
