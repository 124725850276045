import React, { useEffect } from "react";
import "./App.css";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import { loginRequest, loginUpdate } from "./store/actions/loginAction";
import { InfiniteLoader } from "./elements";
import { AnimatePresence } from "framer-motion";
import { getGromaxRequest } from "./store/actions/getGromaxAction";

import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#652278",
      button:"#FD7E50",
    },
    secondary: {
      main: "#FFFFFF",
    },

    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: "'Noto Sans', sans-serif",
    },
  },
});

const App = (props) => {
  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn") === "true";
    const phoneNumber = loggedIn ? sessionStorage.getItem("phoneNumber") : "";
    if (!props.gromaxDevices) {
      props.getGromax();
    }
    if (loggedIn && phoneNumber.length === 10) {
      props.makeLoginRequest({ phone: phoneNumber, otp: "6145" });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.errorMsg && props.errorMsg !== "") {
      new Noty({
        text: props.errorMsg,
        layout: "topRight",
        theme: "bootstrap-v4",
        type: "error",
        progressBar: true,
        timeout: 1000,
      }).show();
    }
  }, [props.errorMsg]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {sessionStorage.getItem("loggedIn") === "true" &&
          props.loadingLogin && <InfiniteLoader />}
        <AnimatePresence transition={{ duration: 0.1 }}>
          {props.isLoggedIn ? (
            <Dashboard
              tractors={props.tractors}
              realtimeList={props.realTimes}
            />
          ) : (
            <Login />
          )}
        </AnimatePresence>
      </ThemeProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  tractors: state.loginReducer.tractors,
  realTimes: state.loginReducer.realTimes,
  isLoggedIn: state.loginReducer.isLoggedIn,
  loadingLogin: state.loginReducer.loading,
  errorMsg: state.errorReducer.msg,
  gromaxDevices: state.gromaxReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  getGromax: () => {
    dispatch(getGromaxRequest());
  },
  makeLoginRequest: ({ phone, otp }) => {
    dispatch(loginRequest({ phone, otp }));
  },
  makeLoginUpdate: ({ tractors, id, name, phone, auth_token }) => {
    dispatch(loginUpdate({ tractors, id, name, phone, auth_token }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
