import * as types from "../types";

const initialState = {
  loading: false,
  dateRangeData: null,
  requestDateRange: null,
  error: null,
};

export default function dateRangeDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.DATE_RANGE_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        requestDateRange: action.payload.requestDateRange,
      };

    case types.DATE_RANGE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        dateRangeData: action.payload.dateRangeData,
      };

    case types.DATE_RANGE_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        dateRangeData: null,
        requestDateRange: null,
        error: action.payload.error,
      };

    case types.DATE_RANGE_SUMMARY_RESET:
        return {
          ...state,
        loading: false,
        requestDateRange: null,
        dateRangeData: null,
        error: null,
    };

    default:
      return state;
  }
}