import { takeLatest, call, put } from "redux-saga/effects";
import { getNotesFailure, getNotesSuccess } from "../actions/getNotesAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncGetNotesSaga(action) {
  try {
    yield put(clearErrors());
    let resNotes = yield call(_api.getNotesAPI, {
      requestUserID: action.payload.requestUserID,
    });
    yield put(getNotesSuccess({ resNotes }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(getNotesFailure({ error: e }));
  }
}
export default function* getNotesSaga() {
  yield takeLatest(types.GET_NOTES_REQUEST, asyncGetNotesSaga);
}