import { takeLatest, call, put } from "redux-saga/effects";
import { deleteNotesFailure, deleteNotesSuccess } from "../actions/deleteNoteAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncDeleteNotesSaga(action) {
  try {
    yield put(clearErrors());
    let resDeleteNote = yield call(_api.deleteNotesAPI, {
      requestDeleteNoteId: action.payload.requestDeleteNoteId,
    });
    yield put(deleteNotesSuccess({ resDeleteNote }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(deleteNotesFailure({ error: e }));
  }
}
export default function* deleteNotesSaga() {
  yield takeLatest(types.DELETE_NOTES_REQUEST, asyncDeleteNotesSaga);
}