import * as types from "../types";

const initialState = {
  userNotes: null,
  error: null,
};

export default function userNotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_ACTION_NOTES_PERFORMED:
      return {
        ...state,
        userNotes: state.userNotes
          ? [...state.userNotes, action.payload.requestNewNote]
          : [action.payload.requestNewNote],
      };

    case types.RESET_USER_ACTION_PATCH_PERFORMED:
      return {
        ...state,
        userNotes: null,
        error: null,
      };

    default:
      return state;
  }
}