import * as types from "../types";

export const userNotesAction = ({ requestNewNote }) => ({
  type: types.USER_ACTION_NOTES_PERFORMED,
  payload: {
    requestNewNote,
  },
});

export const resetFetchUserNotesAction = () => ({
  type: types.RESET_USER_ACTION_NOTES_PERFORMED,
});