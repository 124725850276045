import { select, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import {
  filterTractorSuccess,
  filterTractorFailure,
} from "../actions/filterTractorAction";
import { returnErrors, clearErrors } from "../actions/errorAction";

export const getTractors = (state) => state.loginReducer.allTractors;
export const getRealTimes = (state) => state.loginReducer.allRealTimes;

function* asyncFilterTractorRequest(action) {
  try {
    yield put(clearErrors());
    const { makers, models } = action.payload;
    let tractors = yield select(getTractors);
    let realTimes = yield select(getRealTimes);
    let filteredTractors = [];
    let filteredRealTimes = [];
    if ((makers && makers.length > 0) || (models && models.length > 0)) {
      if (makers && makers.length > 0) {
        tractors.forEach((t, i) => {
          if (makers.includes(t.make) && !filteredTractors.includes(t)) {
            filteredRealTimes.push(realTimes[i]);
            filteredTractors.push(t);
          }
        });
      }
      if (models && models.length > 0) {
        tractors.forEach((t, i) => {
          if (models.includes(t.model) && !filteredTractors.includes(t)) {
            filteredRealTimes.push(realTimes[i]);
            filteredTractors.push(t);
          }
        });
      }
    } else {
      filteredTractors = tractors;
    }
    yield put(
      filterTractorSuccess({
        tractors: filteredTractors,
        realTimes: filteredRealTimes,
      })
    );
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(filterTractorFailure(e));
  }
}

export default function* filterTractorSaga() {
  yield takeLatest(types.FILTER_TRACTOR_REQUEST, asyncFilterTractorRequest);
}
