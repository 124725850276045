import * as types from "../types";

export const addPatchesRequest = ({ requestData }) => ({
  type: types.ADD_PATCHES_REQUEST,
  payload: {
    requestData,
  },
});

export const addPatchesSuccess = ({ res }) => ({
  type: types.ADD_PATCHES_SUCCESS,
  payload: {
    res,
  },
});

export const addPatchesFailure = ({ error }) => ({
  type: types.ADD_PATCHES_FAILURE,
  payload: {
    error: error.message,
  },
});