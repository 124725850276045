import { takeLatest, call, put } from "redux-saga/effects";
import { addNotesFailure, addNotesSuccess } from "../actions/addNewNoteAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncAddNotesSaga(action) {
  try {
    yield put(clearErrors());
    let resAddNotes = yield call(_api.addNotesAPI, {
      requestAddNote: action.payload.requestAddNote,
    });
    yield put(addNotesSuccess({ resAddNotes }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(addNotesFailure({ error: e }));
  }
}
export default function* addNotesSaga() {
  yield takeLatest(types.ADD_NOTES_REQUEST, asyncAddNotesSaga);
}