import React, { Suspense, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Filter from "../assets/Filter.svg";
import Arrow from "../assets/login/Long-arrow.svg";
import TodayTractor from "../assets/todayTractor.svg";
import TodayHour from "../assets/todayTime.svg";
import TodayDistance from "../assets/todayDistance.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import Tractor from "./tractor";
import { AnimateSharedLayout, motion } from "framer-motion";
import Tractordesc from "./TractorDesc";
import { Button, InfiniteLoader } from "../elements";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  filterTractorRequest,
  resetAllFilters,
} from "../store/actions/filterTractorAction";
import Modal from "@material-ui/core/Modal";
import Close from "../assets/home-tractor/close.svg";
import Chip from "@material-ui/core/Chip";
import { realtimeRequest } from "../store/actions/realtimeRequestAction";
import { getTodayStatusRequest } from "../store/actions/getTodayStatusAction"

const GoogleMap = React.lazy(() => import("./GoogleMap/GoogleMap"));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  statusSidebar: {
    overflowY: "scroll",
    height: "150px",
    background: theme.palette.secondary.main,
    padding: "1em",
    marginTop:"1em",
    position:"absolute",
    right:"0",
    borderRadius:"8px 0px 0px 8px"
  },
  todayStatusHeader:{
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "capitalize",
    color:"#333333",
    paddingBottom:"10px"
  },
  todayStatusWrapper:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  todayStatusItem:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    justifyContent:"flex-start",
    gap:"5px",
    margin:"10px 0px 0px 0px"
  },
  todayStatusItemValue:{
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "27px",
    textTransform: "uppercase",
    color:"#000",
  },
  todayStatusItemTitle:{
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color:"#666",
  },
  sidebar: {
    overflowY: "scroll",
    height: "calc(95vh - 220px)",
    background: theme.palette.secondary.main,
    padding: "0 1em",
    //To make Zoom Visible Remove below code
    marginTop:"13.5em",
    position:"absolute",
    right:"0",
    borderRadius:"8px 0px 0px 8px"
  },
  filterOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h2": {
      fontSize: "1.2em",
    },
    padding: "1em 0",
  },
  filterBtn: {
    display: "flex",
    alignItems: "center",
    outline: "none",
    background: "none",
    padding: "0.3em 1em",
    fontSize: "12px",
    gap: "7px",
    border: "none",
    color: "#000",
    textTransform:"uppercase",
    cursor: "pointer",
  },
  tractorListWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  googleMap: {
    alignSelf: "flex-start",
    height: "calc(100vh - 60px)",
    overflowY: "auto",
    flex: 1,
    position: "relative",
  },
  filterOptions: {
    position: "absolute",
    width: "800px",
    height: "550px",
    top: "20%",
    left: "50%",
    transform: "translate(-50%,0%)",
    background: "#FFFFFF",
    outline: "none",
    border: "none",
    borderRadius: "4px",
    padding: "2em",
    display: "flex",
    flexDirection: "column",
  },
  filterAutoComplete: {
    margin: "2em 0",
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    "& > * ": {
      flex: 1,
    },
  },
  filterOptionsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > p": {
      cursor: "pointer",
    },
  },
  sidebarEngineOff: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    background: "#FF0C22",
    position: "relative",
    zIndex: 4,

    "&::before": {
      content: '""',
      position: "absolute",
      top: "-4px",
      left: "-4px",
      bottom: "-4px",
      right: "-4px",
      background: "#BE1E2D",
      zIndex: 1,
      borderRadius: "50%",
      opacity: "0.3",
    },
  },
  sidebarEngineOn: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    background: "#00c441",
    position: "relative",
    zIndex: 4,

    "&::before": {
      content: '""',
      position: "absolute",
      top: "-4px",
      left: "-4px",
      bottom: "-4px",
      right: "-4px",
      background: "#007c29",
      zIndex: 1,
      borderRadius: "50%",
      opacity: "0.3",
    },
  },
}));

const variants = {
  open: { width: "420px" },
  closed: { width: "125px" },
};

function Sidebar({
  tractors,
  realtimeList,
  show,
  setShow,
  selectedTractor,
  setSelectedTractor,
  position,
  setposition,
  showTrips,
  setshowTrips,
  onSelectedTractorChanged,
  onShowTrips,
  ...props
}) {
  const classes = useStyles();
  const [tractorModels, setTractorModels] = useState(null);
  const [tractorMakers, setTractorMakers] = useState(null);
  const [drawingTool,setDrawingTool]=useState();
  const [showAreaButton,setShowAreaButton]=useState(false);
  const [shapes,setShapes]=useState();

  const [addGeofence,setAddGeofence]=useState(false);

  let updateRealtimeTractorRef=useRef(null);

  useEffect(() => {
    setTractorModels([
      ...new Set(props.allTractors.map((tractor) => tractor.model)),
    ]);
    setTractorMakers([
      ...new Set(props.allTractors.map((tractor) => tractor.make)),
    ]);
  }, [props.allTractors]);

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterModel, setFilterModel] = useState("");
  const [filterMake, setFilterMake] = useState("");

  const onFilterSubmit = () => {
    props.filterTractors({ models: filterModel, makers: filterMake });
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    setFilterOpen(filterOpen && show);
  }, [show, filterOpen]);

  useEffect(()=>{
    if(tractors){
      let requestUserID={
        user_id:props.userID,
      };
      props.getOverAllStats({requestUserID})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tractors])

  useEffect(() => {
    if(tractors){
      props.selectRealtimeAPI({phone: sessionStorage.getItem("phoneNumber")});
      if(updateRealtimeTractorRef.current){
        clearInterval(updateRealtimeTractorRef.current);
      }
      updateRealtimeTractorRef.current=setInterval(()=>{
        props.selectRealtimeAPI({phone: sessionStorage.getItem("phoneNumber")});
      },30000);
    }
    return ()=>{
      if(updateRealtimeTractorRef.current){
        clearInterval(updateRealtimeTractorRef.current);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tractors]);

  return (
    <div className={classes.root}>
      <div className={classes.googleMap}>
        <Suspense fallback={<InfiniteLoader />}>
          <GoogleMap
            realTimeList={realtimeList}
            tractorList={tractors}
            selectedPosition={position}
            showTrips={showTrips}
            selectedTractor={selectedTractor}
            tractor={selectedTractor ? selectedTractor.tractor : null}
            realTime={selectedTractor ? selectedTractor.realTime : null}
            position={position}
            show={show}
            setShow={setShow}
            onShowTrips={onShowTrips}
            setSelectedTractor={setSelectedTractor}
            onPositionChange={onSelectedTractorChanged}
            drawingTool={drawingTool}
            setDrawingTool={setDrawingTool}
            showAreaButton={showAreaButton}
            setShowAreaButton={setShowAreaButton}
            shapes={shapes}
            setShapes={setShapes}
            addGeofence={addGeofence}
            setAddGeofence={setAddGeofence}
          />
        </Suspense>
      </div>
      {show && (<motion.div
        className={classes.statusSidebar}
        transition={{ duration: 0.2 }}
        animate="open"
        variants={variants}
      >
        <span className={classes.todayStatusHeader}>Today's Status</span>
        <div className={classes.todayStatusWrapper}>
          <div className={classes.todayStatusItem}>
                <img src={TodayTractor} alt="today-stats-tractor" />
                {props.todayStatsLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <span className={classes.todayStatusItemValue}>{props.todayStats && props.todayStats.data.active_tractors}</span>
                )}
                <span className={classes.todayStatusItemTitle}>Active Tractors</span>
              
            </div>
            <div className={classes.todayStatusItem}>
                <img src={TodayHour} alt="today-stats-time" />
                {props.todayStatsLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <span className={classes.todayStatusItemValue}>{props.todayStats && props.todayStats.data.total_job_time_seconds}</span>
                )}
                <span className={classes.todayStatusItemTitle}>Hrs:MM</span>
            </div>
            <div className={classes.todayStatusItem}>
                <img src={TodayDistance} alt="today-stats-distance" />
                {props.todayStatsLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  <span className={classes.todayStatusItemValue}>{props.todayStats && props.todayStats.data.total_commute_distance_km}</span>
                )}
                <span className={classes.todayStatusItemTitle}>Kms commute</span>
            </div>
        </div>
      </motion.div>)}
      {show && (<motion.div
        className={classes.sidebar}
        transition={{ duration: 0.2 }}
        animate="open"
        variants={variants}
      >
        <motion.div
          className={classes.filterOption}
          style={{
            justifyContent: "space-between",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            All Tractors - {tractors.length ?? 0}
          </motion.h2>
            <motion.button
              className={classes.filterBtn}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <img src={Filter} alt="filter" />
              Filter by
            </motion.button>
        </motion.div>
        {props.filtered && (
          <Chip
            style={{
              marginBottom: "1em",
            }}
            label=" Reset Filters"
            onDelete={() => props.resetFilter()}
            variant="outlined"
          />
        )}
        {filterOpen && (
          <Modal
            open={filterOpen}
            onClose={() => setFilterOpen(!filterOpen)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.filterOptions}>
              <div className={classes.filterOptionsHeader}>
                <h3>Filter By:</h3>
                <p onClick={() => setFilterOpen(!filterOpen)}>
                  <img src={Close} alt="close" /> close
                </p>
              </div>
              <div className={classes.filterAutoComplete}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="tags-maker"
                  options={tractorMakers}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => setFilterMake(value)}
                  defaultValue={props.filterMakers ? props.filterMakers : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Tractor Maker"
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="tags-model"
                  options={tractorModels}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => setFilterModel(value)}
                  defaultValue={props.filterModels ? props.filterModels : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Tractor Model"
                    />
                  )}
                />
              </div>
              <div style={{ marginTop: "auto" }}>
                <Button type="button" onClick={onFilterSubmit} width="100%">
                  <p>Filter</p>
                  <span>
                    <img src={Arrow} alt="arrow" />
                  </span>
                </Button>
              </div>
            </div>
          </Modal>
        )}
        <div className={classes.tractorListWrapper}>
          <AnimateSharedLayout transition={{ duration: 0.3 }}>
            {tractors.map((tractor, index) => (
              <Tractor
                key={tractor.id}
                index={index}
                position={position}
                realTime={realtimeList.find(rt => rt.fb_key === tractors.fb_key) ?? realtimeList[index]}
                tractor={tractor}
                show={show}
                setShow={setShow}
                onShowTrips={onShowTrips}
                setSelectedTractor={setSelectedTractor}
                onPositionChange={onSelectedTractorChanged}
                setShowAreaButton={setShowAreaButton}
                drawingTool={drawingTool}
                setDrawingTool={setDrawingTool}
                shapes={shapes}
                setShapes={setShapes}
                addGeofence={addGeofence}
                setAddGeofence={setAddGeofence}
              />
            ))}
          </AnimateSharedLayout>
        </div>
      </motion.div>)}
      {!show && (
        <Tractordesc
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          setShow={setShow}
          showTrips={showTrips}
          onShowTrips={onShowTrips}
          onPositionChange={onSelectedTractorChanged}
          tractorList={tractors}
          realtimeList={realtimeList}
          drawingTool={drawingTool}
          setDrawingTool={setDrawingTool}
          showAreaButton={showAreaButton}
          setShowAreaButton={setShowAreaButton}
          shapes={shapes}
          setShapes={setShapes}
          addGeofence={addGeofence}
          setAddGeofence={setAddGeofence}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  filterModels: state.loginReducer.filterModels,
  filterMakers: state.loginReducer.filterMakers,
  allTractors: state.loginReducer.allTractors,
  userID: state.loginReducer.id,
  filtered: state.loginReducer.filtered,
  checkRealtime: state.realtimeReducer.realtimeData,
  todayStatsLoading: state.getTodayStatusReducer.loading,
  todayStats: state.getTodayStatusReducer.resTodayStatus,
});

const mapDispatchToProps = (dispatch) => ({
  filterTractors: ({ models, makers }) => {
    dispatch(filterTractorRequest({ models, makers }));
  },
  resetFilter: () => {
    dispatch(resetAllFilters());
  },
  selectRealtimeAPI: ({ phone }) => {
    dispatch(realtimeRequest({ phone }));
  },
  getOverAllStats: ({ requestUserID }) => {
    dispatch(getTodayStatusRequest({ requestUserID }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
