import { takeLatest, call, put } from "redux-saga/effects";
import { getTodayStatusFailure, getTodayStatusSuccess } from "../actions/getTodayStatusAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncGetTodayStatusSaga(action) {
  try {
    yield put(clearErrors());
    let resTodayStatus = yield call(_api.getTodayStatusAPI, {
      requestUserID: action.payload.requestUserID,
    });
    yield put(getTodayStatusSuccess({ resTodayStatus }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(getTodayStatusFailure({ error: e }));
  }
}
export default function* getTodayStatusSaga() {
  yield takeLatest(types.GET_TODAY_STATUS_REQUEST, asyncGetTodayStatusSaga);
}