import * as types from "../types";

export const doneNotesRequest = ({ requestDoneNoteId }) => {
  return {
    type: types.DONE_NOTES_REQUEST,
    payload: { requestDoneNoteId },
  };
};

export const doneNotesSuccess = ({ resDoneNote }) => {
  return {
    type: types.DONE_NOTES_SUCCESS,
    payload: {
      resDoneNote,
    }
  };
};

export const doneNotesFailure = (error) => {
  return {
    type: types.DONE_NOTES_FAILURE,
    payload: {},
    error: error,
  };
};