import * as types from "../types";

export const fetchSatellitePatchRequest = ({ requestPatch }) => ({
  type: types.FETCH_SATELLITE_PATCH_REQUEST,
  payload: {
    requestPatch,
  },
});

export const fetchSatellitePatchSuccess = ({ satellitePatch }) => ({
  type: types.FETCH_SATELLITE_PATCH_SUCCESS,
  payload: {
    satellitePatch,
  },
});

export const fetchSatellitePatchFailure = ({ error }) => ({
  type: types.FETCH_SATELLITE_PATCH_FAILURE,
  payload: {
    error: error.message,
  },
});