import * as types from "../types";

export const getGromaxRequest = () => {
  return {
    type: types.GET_GROMAX_REQUEST,
    payload: {},
  };
};

export const getGromaxSuccess = ({ data }) => {
  return {
    type: types.GET_GROMAX_SUCCESS,
    payload: {
      data,
    },
  };
};

export const getGromaxFailure = (error) => {
  return {
    type: types.GET_GROMAX_FAILURE,
    payload: {},
    error: error,
  };
};
