import fuel0 from '../assets/home-tractor/fuel0.svg';
import fuel1 from '../assets/home-tractor/fuel1.svg';
import fuel2 from '../assets/home-tractor/fuel2.svg';
import fuel3 from '../assets/home-tractor/fuel3.svg';
import fuel4 from '../assets/home-tractor/fuel4.svg';
import fuel5 from '../assets/home-tractor/fuel5.svg';

export const getFuelMeterTank = (a,maxFuel, fuelInLitres)=> {
   let fuelPercent= (fuelInLitres / maxFuel) * 100;

   if (fuelInLitres >= 500) {
      return fuel0;
  } else if (fuelInLitres < 1) {
      return fuel0;
  } else if (fuelInLitres <= 10) {
      return fuel1;
  } else if (fuelPercent <= 25) {
      return fuel2;
  } else if (fuelPercent <= 50) {
      return fuel3;
  } else if (fuelPercent <= 75) {
      return fuel4;
  } else {
      return fuel5;
  }
  }

  export const getFuelMeterText=(a,maxFuel, fuelInLitres) =>{
   let fuelPercent= (fuelInLitres / maxFuel) * 100;

   if (fuelInLitres >= 500) {
      return "Empty";
  } else if (fuelInLitres < 1) {
      return "Empty";
  } else if (fuelInLitres <= 10) {
      return "Low";
  } else if (fuelPercent <= 25) {
      return "Towards Low";
  } else if (fuelPercent <= 50) {
      return "Medium";
  } else if (fuelPercent <= 75) {
      return "High";
  } else {
      return "Full";
  }
  }
