import * as types from "../types";

const initialState = {
  loading: false,
  tractor: null,
  realTime: null,
  dates: null,
  error: {},
};

const tractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TRACTOR_REQUEST:
      return {
        ...state,
        loading: true,
        tractor: action.payload.tractor,
        realTime: action.payload.realTime,
        error: {},
      };
    case types.TRACTOR_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        dates: action.payload.dates,
        error: {},
      };
    case types.TRACTOR_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default tractorReducer;
