import { combineReducers } from "redux";
import otpRequestReducer from "./otpRequestReducer";
import loginReducer from "./loginReducer";
import daySummaryReducer from "./daySummaryReducer";
import tractorReducer from "./tractorReducer";
import errorReducer from "./errorReducer";
import fetchNotificationReducer from "./fetchNotificationReducer";
import getGromaxReducer from "./getGromaxReducer";
import selectDateReducer from "./selectDateReducer";
import realtimeReducer from "./realtimeReducer";
import tractorDetailReducer from "./tractorDetailReducer";
import addPatchesReducer from "./addPatchReducer";
import userPatchReducer from "./userPatchReducer";
import userNotesReducer from "./addNotesReducer";
import localTractorNameReducer from "./localTractorNameReducer";
import getNotesReducer from "./getNotesReducer";
import addNotesReducer from "./addNewNoteReducer";
import deleteNotesReducer from "./deleteNoteReducer";
import editNotesReducer from "./editNoteReducer";
import doneNotesReducer from "./doneNoteReducer";
import satellitePatchReducer from "./satellitePatchReducer";
import dateRangeDataReducer from "./dateRangeDataReducer";
import tractorCompanyReducer from "./tractorCompanyReducer";
import tractorModelReducer from "./tractorModelReducer";
import getAllGeofenceReducer from "./getAllGeofenceReducer";
import editGeofenceReducer from "./editGeofenceReducer";
import getTodayStatusReducer from "./getTodayStatusReducer";

const rootReducer = combineReducers({
  loginReducer,
  otpRequestReducer,
  daySummaryReducer,
  tractorReducer,
  errorReducer,
  realtimeReducer,
  fetchNotificationReducer,
  gromaxReducer: getGromaxReducer,
  dateReducer: selectDateReducer,
  tractorUpdateReducer: tractorDetailReducer,
  addPatchesReducer,
  userPatchReducer,
  userNotesReducer,
  localTractorNameReducer,
  getNotesReducer,
  addNotesReducer,
  deleteNotesReducer,
  editNotesReducer,
  doneNotesReducer,
  satellitePatchReducer,
  dateRangeDataReducer,
  tractorCompanyReducer,
  tractorModelReducer,
  getAllGeofenceReducer,
  editGeofenceReducer,
  getTodayStatusReducer
});

export default rootReducer;
