import * as types from "../types";

const initialState = {
  loading: false,
  phone: null,
  realtimeData: null,
  error: null,
};

export default function realtimeReducer(state = initialState, action) {
  switch (action.type) {
    case types.REALTIME_REQUEST:
      return {
        ...state,
        loading: true,
        phone: action.payload.phone,
      };

    case types.REALTIME_SUCCESS:
      return {
        ...state,
        loading: false,
        realtimeData: action.payload.realtimeData,
      };

    case types.REALTIME_FAILURE:
      return {
        ...state,
        loading: false,
        phone: null,
        realtimeData: null,
        error: action.payload.error,
      };

      case types.REALTIME_RESET:
        return {
          ...state,
          loading: false,
          phone: null,
          realtimeData: null,
          error: null,
        };


    default:
      return state;
  }
}
