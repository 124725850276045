import * as types from "../types";

export const tractorRequest = ({ tractor, realTime }) => {
  return {
    type: types.TRACTOR_REQUEST,
    payload: {
      tractor,
      realTime,
    },
  };
};

export const tractorRequestSuccess = ({ dates }) => {
  return {
    type: types.TRACTOR_REQUEST_SUCCESS,
    payload: {
      dates,
    },
  };
};

export const tractorRequestFailure = (error) => {
  return {
    type: types.TRACTOR_REQUEST_FAILURE,
    payload: {},
    error: error,
  };
};
