import { takeLatest, call, put } from "redux-saga/effects";
import { tractorCompanySuccess, tractorCompanyFailure } from "../actions/tractorCompanyAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncTractorCompanySaga(action) {
  try {
    yield put(clearErrors());
    let resTractorCompany = yield call(_api.tractorCompanyAPI, {
      requestTractorId: action.payload.requestTractorId,
    });
    yield put(tractorCompanySuccess({ resTractorCompany }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(tractorCompanyFailure({ error: e }));
  }
}
export default function* tractorCompanySaga() {
  yield takeLatest(types.TRACTOR_COMPANY_REQUEST, asyncTractorCompanySaga);
}