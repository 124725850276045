import { takeLatest, call, put } from "redux-saga/effects";
import { editNotesFailure, editNotesSuccess } from "../actions/editNoteAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncEditNotesSaga(action) {
  try {
    yield put(clearErrors());
    let resEditNote = yield call(_api.editNotesAPI, {
      requestEditNote: action.payload.requestEditNote,
    });
    yield put(editNotesSuccess({ resEditNote }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(editNotesFailure({ error: e }));
  }
}
export default function* editNotesSaga() {
  yield takeLatest(types.EDIT_NOTES_REQUEST, asyncEditNotesSaga);
}